import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as bankAccountsService from '../../services/bankAccountsService';

const INITIAL_STATE = {
  defaultTransactionAccount: null,
  bankAccounts: [],
  defaultAccount: { amount: 0, currency: { value: 0 } },
  isLoading: false,
  error: null,
};

export const getBankAccounts = createAsyncThunk('bankAccounts/getBankAccounts', async (id, { rejectWithValue }) => {
  try {
    const data = await bankAccountsService.getBankAccounts(id);
    return data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to fetch bank accounts');
  }
});

export const createBankAccounts = createAsyncThunk('bankAccounts/createBankAccounts', async ({currencies, id }, { rejectWithValue }) => {
  try {
    const data = await bankAccountsService.createBankAccounts(currencies,id);
    return data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to create bank accounts');
  }
});

export const fetchDefaultAccount = createAsyncThunk('bankAccounts/fetchDefaultAccount', async (id, { rejectWithValue }) => {
  try {
    const data = await bankAccountsService.getDefaultAccount(id);
    return data;
  } catch (error) {
    return rejectWithValue(error.response?.data || 'Failed to fetch default account');
  }
});

const bankAccountsSlice = createSlice({
  name: 'bankAccounts',
  initialState: INITIAL_STATE,
  reducers: {
    setDefaultTransactionAccount: (state, action) => {
      state.defaultTransactionAccount = action.payload;
    },
    setDefaultAccount: (state, action) => {
      state.defaultAccount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBankAccounts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getBankAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bankAccounts = action.payload;
      })
      .addCase(getBankAccounts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(createBankAccounts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createBankAccounts.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createBankAccounts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(fetchDefaultAccount.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDefaultAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.defaultAccount = action.payload;
      })
      .addCase(fetchDefaultAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setDefaultTransactionAccount, setDefaultAccount } = bankAccountsSlice.actions;
export default bankAccountsSlice.reducer;
