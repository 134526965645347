import { jwtDecode } from "jwt-decode";

const localStorageService = {
    setItem(key, value) {
      try {
        const serializedValue = JSON.stringify(value);
        localStorage.setItem(key, value);
      } catch (error) {
        console.error(`Error setting item to localStorage: ${error}`);
      }
    },
  
    getItem(key) {
      try {
        const serializedValue = localStorage.getItem(key);
        
        return serializedValue;
      } catch (error) {
        console.error(`Error getting item from localStorage: ${error}`);
        return null;
      }
    },
  
    removeItem(key) {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        console.error(`Error removing item from localStorage: ${error}`);
      }
    },
  
    clear() {
      try {
        localStorage.clear();
      } catch (error) {
        console.error(`Error clearing localStorage: ${error}`);
      }
    },

    getDecodedToken() {
      try {
        const token = this.getItem('token'); 
        if (!token) {
          return null;
        }
        return jwtDecode(token); 
      } catch (error) {
        console.error(`Error decoding token: ${error}`);
        return null; 
      }
    }
  };
  
  export default localStorageService;