import React from 'react';
import './dashboard-header.css'; 
import { useNavigate } from 'react-router';

const DashboardHeader = ({ type, title }) => {
  const navigate = useNavigate();


  return (
    <div className="dashboard-header" >
      {type === 'text' ? (
        <span className="title">{title}</span>
      ) : (
        <>
          <div className="action" onClick={() => navigate('/send-money')}>
            <div className="wallet-item">
              <img src={require('../assets/send.png')} alt="Send" className="icon" />
            </div>
            <span className="item-text">Send</span>
          </div>
          <div className="action" onClick={() => navigate('/exchange')}>
            <div className="wallet-item">
              <img src={require('../assets/transfer.png')} alt="Transfer" className="icon" />
            </div>
            <span className="item-text">Transfer</span>
          </div>
          <div className="action" onClick={() =>  navigate('/top-up')}>
            <div className="wallet-item wallet-item-large">
              <img src={require('../assets/topup.png')} alt="Top Up" className="icon" />
            </div>
            <span className="item-text item-text-bottom">Top up</span>
          </div>
          <div className="action" onClick={() =>  navigate('/pay')}>
            <div className="wallet-item wallet-item-large">
              <img src={require('../assets/Pay.png')} alt="Pay" className="icon" />
            </div>
            <span className="item-text item-text-bottom">Pay</span>
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardHeader;
