import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as transactionsService from '../../services/transactionsService';


export const fetchTransactions = createAsyncThunk(
  'transactions/fetchTransactions',
  async ({ userId, size }, { rejectWithValue }) => {
    try {
      const response = await transactionsService.getTransactions(userId, size);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch transactions');
    }
  }
);

export const fetchStoreTransactions = createAsyncThunk(
  'transactions/fetchStoreTransactions',
  async ({ userId, type, storeId }, { rejectWithValue }) => {
    try {
      const response = await transactionsService.getStoreTransactions(userId, type, storeId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch store transactions');
    }
  }
);

export const fetchTransactionById = createAsyncThunk(
  'transactions/fetchTransactionByID',
  async (transactionID, { rejectWithValue }) => {
    try {
      const response = await transactionsService.getTransactionById(transactionID);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch transaction by ID');
    }
  }
);

export const fetchTransactionInterval = createAsyncThunk(
  'transactions/fetchTransactionInterval',
  async ({ userId, endDate, startDate }, { rejectWithValue }) => {
    console.log(endDate);
    
    try {
      const response = await transactionsService.getTransactionInterval(userId, endDate, startDate);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch transaction interval');
    }
  }
);

export const fetchPDF = createAsyncThunk(
  'transactions/fetchPDF',
  async ({ userId, endDate, startDate }, { rejectWithValue }) => {
    try {
      const response = await transactionsService.createTransactionPDF(userId, endDate, startDate);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to create PDF');
    }
  }
);

export const fetchPOSTransactions = createAsyncThunk(
    'transactions/fetchPOSTransactions',
    async ({ posId, type, userId }, { rejectWithValue }) => {
      try {
        const response = await transactionsService.getPOSTransactions(posId, type, userId);
        return response.data; 
      } catch (error) {
        return rejectWithValue(error.response?.data || 'Failed to fetch POS transactions');
      }
    }
  );

const initialState = {
  transactions: [],
  pressedTransaction: {},
  status: 'idle',
  error: null,
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setPressedTransaction: (state, action) => {
      state.pressedTransaction = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.transactions = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      .addCase(fetchStoreTransactions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStoreTransactions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.storeTransactions = action.payload;
      })
      .addCase(fetchStoreTransactions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      .addCase(fetchTransactionById.fulfilled, (state, action) => {
        state.pressedTransaction = action.payload;
      })
      .addCase(fetchTransactionById.rejected, (state, action) => {
        state.error = action.payload;
      })

      .addCase(fetchTransactionInterval.fulfilled, (state, action) => {
        state.transactions = action.payload;
      })
      .addCase(fetchTransactionInterval.rejected, (state, action) => {
        state.error = action.payload;
      })

      .addCase(fetchPDF.fulfilled, (state, action) => {
      })
      .addCase(fetchPDF.rejected, (state, action) => {
        state.error = action.payload;
      })

      .addCase(fetchPOSTransactions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPOSTransactions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.posTransactions = action.payload; 
      })
      .addCase(fetchPOSTransactions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { setPressedTransaction } = transactionsSlice.actions;

export default transactionsSlice.reducer;
