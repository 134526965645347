import React from 'react';
import './credit-card.css';

export const CreditCard = (props) => {
  return (
    <div
      className={`credit-card ${props.rotate ? 'rotated' : ''}`}
      style={props.customStyles}
    >
      <div className="credit-card-header">
        <div className="logo-container">
          <img
            src={require('../assets/360logo.png')}
            alt="Text Logo"
            className="credit-card-logo"
          />
        </div>
      </div>

      <div className="credit-card-number">
        <p className="card-number">{props?.number}</p>
      </div>

      <div className="credit-card-info">
        <div className="card-info-section">
          <p className="info-title">Valid Thru</p>
          <p className="info-value">{props?.expireDate}</p>
        </div>
        <div className="card-info-section">
          <p className="info-title">CVV</p>
          <p className="info-value">{props?.cvv}</p>
        </div>
        <div className="symbol-container">
          <img
            src={require('../assets/logoSymbol.png')}
            alt="Symbol Logo"
            className="credit-card-symbol"
          />
        </div>
      </div>

      {props?.amount && (
        <div className="credit-card-amount-container">
          <div className="credit-card-amount">
            <p>{props?.amount}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreditCard;
