import React from 'react';
import DashboardLayout from '../layouts/dashboard-layout';
import DashboardHeader  from '../components/dashboard-header';
import './dashboard-page-layout.css'; 
import Button from '../components/button';
const  DashboardPageLayout = ({
  title,
  alignType = 'default',
  children,
  hideMainBtn = false,
  mainBtnText,
  onMainBtnClick,
  disableMainBtn = false,
  extraHeightForScroll = 0,
}) => {
  
  return (
    <DashboardLayout type="text">
      <DashboardHeader type="text" title={title} />
      
      <div className={`layout-container ${alignType !== 'default' ? 'center-align' : ''}`}>
        {children}
      </div>
      
      {!hideMainBtn && (
        <div
          className="button-container"
          style={{
            marginBottom: `${extraHeightForScroll}vh`,
          }}
        >
          <Button
            text={mainBtnText}
            onClick={onMainBtnClick}
            disabled={disableMainBtn}
            customStyle={disableMainBtn ? 'button-disabled' : 'button-active'}
          />
        </div>
      )}
    </DashboardLayout>
  );
};

export default DashboardPageLayout;
