import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as contactsService from '../../services/contactsService';  

export const fetchContacts = createAsyncThunk(
  'contacts/fetchContacts',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await contactsService.getContacts(userId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch contacts');
    }
  }
);

export const fetchContactBankAccounts = createAsyncThunk(
  'contacts/fetchContactBankAccounts',
  async (contactId, { rejectWithValue }) => {
    try {
      const response = await contactsService.getContactBankAccounts(contactId);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch contact bank accounts');
    }
  }
);

const contactsSlice = createSlice({
  name: 'contacts',
  initialState: {
    contacts: [],
    contactBankAccounts: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContacts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchContacts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.contacts = action.payload;
      })
      .addCase(fetchContacts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchContactBankAccounts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchContactBankAccounts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.contactBankAccounts = action.payload;
      })
      .addCase(fetchContactBankAccounts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default contactsSlice.reducer;
