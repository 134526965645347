import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as cardService from '../../services/cardService';  



export const getCard = createAsyncThunk('card/getCard', async (id) => {
  const response = await cardService.getCard(id);
  return response;
});

export const getForexRate = createAsyncThunk('card/getForexRate', async (params) => {
  const response = await cardService.getForexRate(params);
  return response;
});

const INITIAL_STATE = {
    cardData: '',           
    isForex: false,          
    forexRate: {},           
    forexRateNewContact: {}, 
  };

const cardSlice = createSlice({
  name: 'card',
  initialState: INITIAL_STATE,
  reducers: {
    toggleForex(state, action) {
      state.isForex = action.payload;
    },
    saveForexRate(state, action) {
      state.forexRate = action.payload;
    },
    saveForexRateNewContact(state, action) {
      state.forexRateNewContact = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCard.fulfilled, (state, action) => {
        state.cardData = action.payload;
      })
      .addCase(getCard.rejected, (state, action) => {
      })
      .addCase(getForexRate.fulfilled, (state, action) => {
        if (action.payload) {
          state.forexRate = action.payload;
          state.isForex = true;
        } else {
          state.isForex = false;
        }
      })
      .addCase(getForexRate.rejected, (state, action) => {
      });
  },
});

export const { toggleForex, saveForexRate, saveForexRateNewContact } = cardSlice.actions;
export default cardSlice.reducer;
