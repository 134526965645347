import React from 'react';
import './transaction-card.css'; 
import { Comafy } from '../helpers';

const TransactionCard = ({ ammount, title, subTitle, currency, onClick, retailTransaction, containerStyle }) => {
  const formattedAmount = ammount ? `${ammount >= 0 ? '+' : ''} ${Comafy(ammount)} ${String(currency)}` : '';

  const cardStyle = {
    ...containerStyle,
    // width: retailTransaction ? '98%' : '70%',
    height: '8vh',
    borderColor: '#FFD700',
    marginRight: '3%',
    borderRadius: '2vw',
    borderStyle: 'solid',
    flexDirection: 'row',
    borderWidth: 2,
    alignContent: 'center',
    alignItems: 'center',
    marginBottom: retailTransaction ? 0 : '10%',
    boxShadow: '0px 3px 4.65px rgba(0, 0, 0, 0.29)',
    marginTop: 10,
    elevation: 7,
  };

  return (
    <div className="transaction-card" style={cardStyle} onClick={onClick}>
      <div className="transaction-info">
        <span className="transaction-title">{title}</span>
        <span className="transaction-subtitle">{subTitle}</span>
      </div>
      <div className="transaction-amount">
        <span className={ammount <= 0 ? 'transaction-negative' : 'transaction-positive'}>
          {formattedAmount}
        </span>
      </div>
    </div>
  );
};

export default TransactionCard;
