import ExchangeBetweenAccountsSuccess from "../views/transfer/exchange-between-accounts-success";
import ExchangeBetweenAccounts from "../views/transfer/exchange-between-accounts";
import WalletPage from "../views/wallet/wallet-page";
import { createBrowserRouter } from "react-router-dom";
import TransactionsPage from "../views/transactions/transactions";
import TransactionPage from "../views/transactions/transaction/transaction";
import SendMoneyPage from "../views/send-money/send-money";
import PayPage from "../views/pay/pay";
import TopUpPage from "../views/top-up/top-up";

const router = createBrowserRouter([
    {
        path: '/',
        element: <WalletPage />
    },
    {
        path: 'exchange',
        element: <ExchangeBetweenAccounts />,
    },
    {
        path: 'exchange/success',
        element: <ExchangeBetweenAccountsSuccess />
    },
    {
        path: 'transactions',
        element: <TransactionsPage />,

    }, 
    {
        path: 'transaction',
        element: <TransactionPage />
    },
    {
        path: 'send-money', 
        element: <SendMoneyPage/>
    },
    {
        path: 'top-up', 
        element: <TopUpPage/>
    },
    {
        path: 'pay',
        element: <PayPage/>
    }
])


export default router;