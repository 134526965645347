import Axios from 'axios';

const authInstance = Axios.create({
  baseURL: 'https://lyned.nafram.org/lyned/auth/LYNED_CLIENT',
  headers: {
    'Content-Type': 'application/json',
  },
});



export default authInstance;
