import React from 'react';
import './button.css'; 

const Button = ({ text, onClick, disabled, outline, textColor, customStyle }) => {
  const buttonClass = outline ? 'button-outline' : 'button-filled';
  const disabledClass = disabled ? 'button-disabled' : '';
  const styles = customStyle ? customStyle : '';
  return (
    <button
      className={`button ${buttonClass} ${disabledClass} ${styles}`}
      onClick={!disabled ? onClick : null}
      disabled={disabled}
      style={{ color: textColor || undefined }}
    >
      {text}
    </button>
  );
};

export default Button;
