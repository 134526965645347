import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import './transaction.css';
import DashboardLayout from '../../../layouts/dashboard-layout';
import DashboardHeader from '../../../components/dashboard-header';

const TransactionPage = () => {
  const transData = useSelector(state => state.transactions.pressedTransaction);

  return (
    <DashboardLayout type="text">
      <DashboardHeader type="text" title="TRANSACTION" />
      <div className="transaction-page-container">
        {transData.paymentDetails === 'Initial Money Credit Card' ? (
          <Fragment>
            <h2 className="transaction-title">Receiver Name</h2>
            <p className="transaction-detail">
              {`${String(transData?.receiver?.firstName)} ${String(transData?.receiver?.lastName)}`}
            </p>

            <h2 className="transaction-title">Receiver CAN</h2>
            <p className="transaction-detail">
              {String(transData?.receiverAccount?.iban)}
            </p>

            <h2 className="transaction-title">Payment Details</h2>
            <p className="transaction-detail">
              {String(transData?.paymentDetails)}
            </p>
          </Fragment>
        ) : (
          <Fragment>
            <h2 className="transaction-title">Sender Name</h2>
            <p className="transaction-detail">
              {`${String(transData?.sender?.firstName)} ${String(transData?.sender?.lastName)}`}
            </p>

            <h2 className="transaction-title">Sender CAN</h2>
            <p className="transaction-detail">
              {String(transData?.senderAccount?.iban)}
            </p>

            {transData?.receiver?.companyName == null ? (
              <Fragment>
                <h2 className="transaction-title">Receiver Name</h2>
                <p className="transaction-detail">
                  {`${String(transData?.receiver?.firstName)} ${String(transData?.receiver?.lastName)}`}
                </p>
              </Fragment>
            ) : (
              <Fragment>
                <h2 className="transaction-title">Receiver Name</h2>
                <p className="transaction-detail">
                  {transData?.receiver?.companyName}
                </p>
              </Fragment>
            )}

            <h2 className="transaction-title">Receiver CAN</h2>
            <p className="transaction-detail">
              {String(transData?.receiverAccount?.iban)}
            </p>

            {transData?.paymentDetails && (
              <Fragment>
                <h2 className="transaction-title">Payment Details</h2>
                <p className="transaction-detail">
                  {String(transData?.paymentDetails)}
                </p>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </DashboardLayout>
  );
};

export default TransactionPage;
