import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardPageLayout from '../../layouts/dashboard-page-layout';
import ForexData from '../../components/forex-data';
import Modal from 'react-modal';
import Select from 'react-select';
import moment from 'moment';
import './exchange-between-accounts.css';
// import {
//   sendMoneyBetweenAccounts,
//   sendMoneyForexBetweenAccounts,
//   getBankAccountsConfig,
//   getTransferRateConfig
// } from '../../../state/actions/coreActions';
import { Comafy } from '../../helpers';
import { getBankAccounts } from '../../services/bankAccountsService';
import CustomInput from '../../components/form/custom-input';
import { getTransferRate, sendMoneyBetweenAccounts, sendMoneyForexBetweenAccounts } from '../../services/cardService';
import { useNavigate } from 'react-router';
import CustomSelect from '../../components/form/custom-select';
import Button from '../../components/button';

const ExchangeBetweenAccounts = () => {
    const [friendBankAccounts, setFriendBankAccounts] = useState([]);
    const [fromAccountSelectedIban, setFromAccountSelectedIban] = useState(null);
    const [toAccountSelectedIban, setToAccountSelectedIban] = useState(null);
    const [ammountInput, setAmmountInput] = useState('');
    const [accountFromAmountToShow, setAccountFromAmountToShow] = useState(null);
    const [accountFromCurrencyToShow, setAccountFromCurrencyToShow] = useState(null);
    const [accountToAmountToShow, setAccountToAmountToShow] = useState(null);
    const [accountToCurrencyToShow, setAccountToCurrencyToShow] = useState(null);
    const [exchangeRate, setExchangeRate] = useState(null);
    const [amountExchanged, setAmountExchanged] = useState(null);
    const [isForex, setIsForex] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBankAccounts = async () => {
            const response = await getBankAccounts(user.id);

            const accounts = response.map(item => ({
                label: item.iban,
                value: item.iban,
                currency: item.currency.id,
                amount: item.amount,
                currencyValue: item.currency.value,
            }));
            setFriendBankAccounts(accounts);
            setIsLoading(false);
        };
        fetchBankAccounts();
    }, [user.id]);

    const handleAmountInput = (value) => {
        let formattedValue = value.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        setAmmountInput(formattedValue);
    };

    const handleBlur = async () => {
        const fromCurrency = friendBankAccounts.find(acc => acc.value === fromAccountSelectedIban)?.currency;
        const toCurrency = friendBankAccounts.find(acc => acc.value === toAccountSelectedIban)?.currency;

        if (fromCurrency && toCurrency && ammountInput) {
            console.log(ammountInput);

            const response = await getTransferRate(+ammountInput.replace(/,/g, ''), fromCurrency, toCurrency);
            setExchangeRate(response.data.exchangeRate);
            setAmountExchanged(response.data.amountExchanged);
            setIsForex(response.data.exchangeRate !== 1);
        }
    };

    const handleSendMoneyPress = () => {
        setVisibleModal(true);
    };

    const handleAcceptClick = async () => {
        try {
            const paymentDetails = {
                amount: +ammountInput.replace(/,/g, ''),
                creationDate: moment().toISOString(),
                exchangeRate: isForex ? exchangeRate : 0,
                ibanReceiver: toAccountSelectedIban,
                ibanSender: fromAccountSelectedIban,
                idSender: user.id,
                idReceiver: user.id,
                paymentDetails: 'Exchange between accounts',
                toCurrency: accountToCurrencyToShow,
                amountChanged: amountExchanged
            };
            console.log(paymentDetails.amount);

            if (isForex) {
                await sendMoneyForexBetweenAccounts(paymentDetails);
            } else {
                await sendMoneyBetweenAccounts(paymentDetails);
            }
            navigate("success", {
                state: {
                    amount: `${paymentDetails.amount} ${accountFromCurrencyToShow}`, 
                    fullName: user.firstName + ' ' + user.lastName,
                    onlyAmount: paymentDetails.amount,
                    onlyCurrency: accountFromCurrencyToShow, 
                    fromAccountIban: paymentDetails.ibanSender,
                    toAccountIban: paymentDetails.ibanReceiver,
                    exchangeRate: isForex ? paymentDetails.exchangeRate : 0, 
                    toCurrency: paymentDetails.toCurrency,
                    amountChanged: paymentDetails.amountChanged,
                },
            });

        } catch (error) {
            console.error("Error in transaction: ", error);
        }

        setVisibleModal(false);
    };


    return (
        <div className="exchange-container">
            <DashboardPageLayout
                alignType={'default'}
                title={'TRANSFER'}
                extraHeightForScroll={15}
                disableMainBtn={!fromAccountSelectedIban || !toAccountSelectedIban || ammountInput <= 0}
                onMainBtnClick={handleSendMoneyPress}
                mainBtnText={'Send money'}
            >
                <h3 className="exchange-title">Exchange money between your accounts</h3>

                <CustomSelect
                    label="From account"
                    value={fromAccountSelectedIban}
                    onChange={option => {
                        setFromAccountSelectedIban(option.value);
                        setAccountFromAmountToShow(option.amount);
                        setAccountFromCurrencyToShow(option.currencyValue);
                    }}
                    options={friendBankAccounts}
                />

                {accountFromAmountToShow && (
                    <p className="account-info">
                        Amount: {Comafy(accountFromAmountToShow)} {accountFromCurrencyToShow}
                    </p>
                )}
                <CustomSelect
                    label="To account"
                    value={toAccountSelectedIban}
                    onChange={option => {
                        setToAccountSelectedIban(option.value);
                        setAccountToAmountToShow(option.amount);
                        setAccountToCurrencyToShow(option.currencyValue);
                    }}
                    options={friendBankAccounts.filter(acc => acc.value !== fromAccountSelectedIban)}
                />

                {accountToAmountToShow && (
                    <p className="account-info">
                        Amount: {Comafy(accountToAmountToShow)} {accountToCurrencyToShow}
                    </p>
                )}

                <CustomInput
                    className="input-amount"
                    type="text"
                    value={ammountInput}
                    label="Amount"
                    placeholder="0"
                    onChange={e => handleAmountInput(e.target.value)}
                    onBlur={handleBlur}
                />

                {isForex && (
                    <ForexData
                        senderCurrency={accountFromCurrencyToShow}
                        senderValue={ammountInput}
                        receiverCurrency={accountToCurrencyToShow}
                        receiverValue={amountExchanged}
                    />
                )}

                <Modal
                    isOpen={visibleModal}
                    onRequestClose={() => setVisibleModal(false)}
                    className="modal"
                >
                    <h2>Please confirm this transaction:</h2>
                    <p>FROM: {fromAccountSelectedIban}</p>
                    <p>TO: {toAccountSelectedIban}</p>
                    <p>Amount: {ammountInput}</p>
                    {amountExchanged && <p>Amount Exchanged: {amountExchanged}</p>}
                    <Button onClick={handleAcceptClick} text="Confirm"></Button>
                    <Button onClick={() => setVisibleModal(false)} text="Close" outline={true}></Button>
                </Modal>
            </DashboardPageLayout>
        </div>
    );
};

export default ExchangeBetweenAccounts;
