import apiInstance from '../axios/apiInstance';
import localStorageService from './localStorageService';
import {jwtDecode} from 'jwt-decode';

export const getBankAccounts = async (id) => {

  const response = await apiInstance.get(`/core/accounts?userID=${id}`);
  return response.data;
};

export const createBankAccounts = async (currencies, id) => {
  const response = await apiInstance.post(`/core/accounts?userID=${id}`, JSON.stringify(currencies));
  return response.data;
};

export const getDefaultAccount = async (id) => {
  const response = await apiInstance.get(`/core/account/default?userID=${id}`);
  return response.data;
};
