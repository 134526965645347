import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './exchange-between-accounts-success.css'; 
import { Comafy } from '../../helpers';
import checkIcon from '../../assets/largeCheck.png'; 
import DashboardPageLayout from '../../layouts/dashboard-page-layout';
import { useSelector } from 'react-redux';

const ExchangeBetweenAccountsSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const paramsShort = location.state || {}; 
    const userType = useSelector((state) => state.user.userType); 
    
    const handleNavigate = () => {
      if (userType === 2) {
        navigate('/');
      } else if (userType === 1) {
        navigate('/');
      } else if (userType === 3) {
        navigate('/');
      }
    };
  
    return (
      <DashboardPageLayout
        title="MONEY SENT"
        extraHeightForScroll={5}
        onMainBtnClick={handleNavigate}
        mainBtnText="Go to wallet"
      >
        <div className="success-screen-container">
          <div className="success-check-icon">
            <img src={checkIcon} alt="Success" />
          </div>
          <h1 className="success-title">SUCCESS!</h1>
          <div className="success-amount">
            <span>
              {paramsShort.topUp
                ? `${Comafy(paramsShort.onlyAmount)} ${paramsShort.currency}`
                : `${Comafy(paramsShort.onlyAmount)} ${paramsShort.onlyCurrency}`}
            </span>
            <span> sent from</span>
          </div>
          <p className="success-iban">{paramsShort.fromAccountIban}</p>
  
          {paramsShort.exchangeRate === 0 ? (
            <p className="success-to">to</p>
          ) : (
            <div className="success-forex-info">
              <span>to {Comafy(paramsShort.amountChanged)} {paramsShort.toCurrency}</span>
            </div>
          )}
  
          <p className="success-iban">{paramsShort.toAccountIban}</p>
        </div>
      </DashboardPageLayout>
    );
  };
  
  export default ExchangeBetweenAccountsSuccess;
