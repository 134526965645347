import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/user.slice';
import bankAccountsSlice from './slices/bankAccounts.slice';
import cardSlice from './slices/card.slice';
import transactionsSlice from './slices/transactions.slice';
import contactsSlice from './slices/contacts.slice';

const store = configureStore({
  reducer: {
    user: userSlice, 
    bankAccounts: bankAccountsSlice, 
    card: cardSlice,
    transactions: transactionsSlice,
    contacts: contactsSlice
  },
});

export default store;
