import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LocalStorageService from '../../services/localStorageService'; 
import { login } from '../../services/authService';
import * as userService from '../../services/userService';

export const loginUser = createAsyncThunk('session/loginUser', 
  async ({ username, password }, { rejectWithValue }) => {
  try {
    
     await login(username, password);
    
    return { username };
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const getUserDetails = createAsyncThunk(
  'session/getUserDetails',
  async (username, { rejectWithValue }) => {
    try {
      const userDetails = await userService.getUserDetails(username);
      
      return userDetails;
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to fetch user details');
    }
  }
);

const initialState = {
  id: null,
  username: null,
  password: null,
  firstName: null,
  lastName: null,
  email: null,
  phoneNumber: null,
  citizenship: null,
  country: null,
  placeOfBirth: null,
  dateOfBirth: null,
  sex: null,
  legalIdType: null,
  legalIssuingAuthority: null,
  legalIssueDate: null,
  legalExpirationDate: null,
  frontIdentificationCardPath: null,
  backIdentificationCardPath: null,
  selfiePath: null,
  status: null,
  finishedFirstDeposit: false,
  tinCard: null,
  tinCardCompany: null,
  loginStatus: null,
  loginError: null,
};

const userSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    logout(state) {
      state.id = null;
      state.username = null;
      state.token = null;
      state.loginStatus = null;
      state.loginError = null;
      LocalStorageService.removeItem('token'); 
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loginStatus = 'loading';
        state.loginError = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.username = action.payload.username
        state.loginStatus = 'succeeded';
        state.loginError = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loginStatus = 'failed';
        state.loginError = action.payload || 'Failed to login';
      })
      .addCase(getUserDetails.pending, (state) => {
        state.loginError = null;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        return {
          ...state, 
          ...action.payload, 
          loginError: null, 
        };
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.loginError = action.payload || 'Failed to fetch user details';
      })
  },
});

export const {  logout } = userSlice.actions;

export default userSlice.reducer;
