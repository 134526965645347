import authInstance from '../axios/authInstance';
import { jwtDecode } from 'jwt-decode';
import localStorageService from './localStorageService';

export const login = async (username, password) => {
  try {
    const response = await authInstance.post('/generateToken', {
      username,
      password,
    });

    const token = response.data.token;
    
    localStorageService.setItem('token', token);

  } catch (error) {
    throw new Error(error.response?.data || 'Login failed');
  }
};
