import React, { useState, useEffect } from 'react';
import './forex-data.css';

const ForexData = ({ senderCurrency, senderValue, receiverCurrency, receiverValue }) => {
  const [isVisibleExchangeRate, setIsVisibleExchangeRate] = useState(false);
  const [mutatedBla, setMutatedBla] = useState(getBla(senderValue, receiverValue));

  useEffect(() => {
    const interval = setInterval(() => {
      setMutatedBla(getBla(senderValue, receiverValue));
    }, 1000);
    return () => clearInterval(interval);
  }, [senderValue, receiverValue]);

  const showExchangeRate = () => {
    setIsVisibleExchangeRate(!isVisibleExchangeRate);
  };

  function getBla(senderValue, receiverValue) {
    function getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const result = receiverValue / senderValue;
    return `${result.toFixed(6)}${getRandomInt(10, 99)}`;
  }

  return (
    <div className="forex-container">
      <div className="forex-section">
        <div className="currency-box">
          <span className="currency">{senderCurrency}</span>
          <span className="value">{senderValue.toLocaleString()}</span>
        </div>
        
        <div className="exchange-box">
          {isVisibleExchangeRate && (
            <div className="exchange-popup">
              <p className="exchange-text">
                {`1 ${senderCurrency} = ${mutatedBla} ${receiverCurrency}`}
              </p>
            </div>
          )}
          <button className="exchange-button" onClick={showExchangeRate}>
            <img src={require('../assets/exchangeIcon.png')} alt="Exchange Icon" className="exchange-icon" />
          </button>
        </div>

        <div className="currency-box">
          <span className="currency">{receiverCurrency}</span>
          <span className="value">{receiverValue.toLocaleString()}</span>
        </div>
      </div>
    </div>
  );
};

export default ForexData;
