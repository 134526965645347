import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './top-up.css';
import DashboardPageLayout from '../../layouts/dashboard-page-layout';
import CustomInput from '../../components/form/custom-input';
import CustomSelect from '../../components/form/custom-select';
import { getForexRate } from '../../store/slices/card.slice';
import { useNavigate } from 'react-router';


const TopUpPage = () => {
    const dispatch = useDispatch();
    const { bankAccounts, defaultAccount } = useSelector(state => state.bankAccounts);


    const [selectedCurrency, setSelectedCurrency] = useState({ value: '' });
    const [selectedIban, setSelectedIban] = useState('');
    const [cardNumberInput, setCardNumberInput] = useState('');
    const [expiryDateInput, setExpiryDateInput] = useState('');
    const [ccvInput, setCcvInput] = useState('');
    const [ammountInput, setAmmountInput] = useState('');
    const navigate = useNavigate();
    const [selectedAmount, setSelectedAmount] = useState(0);
    const [isCurrencyValid, setIsCurrencyValid] = useState(false);

    useEffect(() => {
        const dataCopy = bankAccounts.map((item, index) => ({
            iban: item.iban,
            accountId: item.id,
            amount: item.amount,
            description: item.currency.description,
            id: item.currency.id,
            value: item.id,
            key: index,
            label: item.currency.value
        }));

        const defaultAccountData = dataCopy.find(item => item.id === defaultAccount.currency.id);
        if (defaultAccountData) {
            setSelectedCurrency(defaultAccountData);
            setSelectedIban(defaultAccountData.iban);
            setSelectedAmount(defaultAccountData.amount);
        }
    }, [bankAccounts, defaultAccount]);

    const handleAmountFocus = () => {
        let amountWithDecimals = ammountInput.split('.');
        if (ammountInput.includes('.')) {
            setAmmountInput(amountWithDecimals[0]);
        }
    };

    const handleBlur = () => {
        if (ammountInput !== '' && !ammountInput.includes('.')) {
            setAmmountInput(ammountInput + '.00');
        }
    };

    const handleAmountInput = (value) => {
        let valueWithCommas = value.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let valueWithoutCommas = value.replace(/,/g, '');
        setAmmountInput(valueWithCommas);

        setTimeout(() => {
            setTimeout(() => {
                if (expiryDateInput && selectedIban && selectedCurrency?.id) {
                    dispatch(getForexRate({
                        receiverIban: expiryDateInput,
                        senderIban: selectedIban,
                        amount: valueWithoutCommas,
                        fromIdCurrency: selectedCurrency.id
                    }));
                }
            }, 1000);
        }, 1000);
    };

    const handleCurrencySelect = (option) => {
        setSelectedCurrency(option);
        setSelectedIban(option.iban);
        setSelectedAmount(option.amount);
        setIsCurrencyValid(true);

        if (!isNextDisabled()) {
            // dispatch(isForex(expiryDateInput, option.iban, ammountInput, option.id));
        }
    };

    const isNextDisabled = () => {
        return !cardNumberInput || !expiryDateInput || !ccvInput || !isCurrencyValid || ammountInput <= 0;
    };

    const handleNextClick = () => {
        navigate(-1);
    }


    return (
        <div className='top-up-page-container'>
            <DashboardPageLayout
                title="TOP UP"
                disableMainBtn={isNextDisabled()}
                onMainBtnClick={handleNextClick}
                mainBtnText="Next"
                extraHeightForScroll={13}
            >

                <CustomInput
                    type='number'
                    label="Card Number"
                    value={cardNumberInput}
                    onChange={e => setCardNumberInput(e.target.value)}
                    isCardInput={true}
                />
                <CustomInput
                    label="Expiry Date"
                    value={expiryDateInput}
                    onChange={(e) => setExpiryDateInput(e.target.value)}
                    isCardDate={true}
                />
                <CustomInput
                    label="CVC/CCV"
                    value={ccvInput}
                    onChange={e => setCcvInput(e.target.value)}
                    isCVV={true}
                />
                <div className='amount-currency-container'>
                    <CustomSelect
                        value={selectedCurrency}
                        onChange={(option) => handleCurrencySelect(option)}
                        options={bankAccounts.map(acc => ({
                            value: acc.iban,
                            label: acc.currency.value,
                            amount: acc.amount,
                            currency: acc.currency.value
                        }))}
                    />
                    <CustomInput
                        type="number"
                        label="Amount"
                        value={ammountInput}
                        onChange={e => handleAmountInput(e.target.value)}
                        onFocus={handleAmountFocus}
                        onBlur={handleBlur}
                    >

                    </CustomInput>
                </div>

            </DashboardPageLayout>
            <div className='partners-container'>
                <img className='airtel-image' src={require('../../assets/airtel-money.jpg')} />
                <img src={require('../../assets/footer-partners-mobile.png')} />
            </div>
        </div>
    );
};

export default TopUpPage;
