import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import './transactions.css';
import { useDispatch, useSelector } from 'react-redux';
import apiInstance from '../../axios/apiInstance';
import DashboardLayout from '../../layouts/dashboard-layout';
import DashboardHeader from '../../components/dashboard-header';
import TransactionCard from '../../components/transaction-card';
import CustomInput from '../../components/form/custom-input';
import Button from '../../components/button';
import { fetchTransactionById, fetchTransactionInterval } from '../../store/slices/transactions.slice';
import { useNavigate } from 'react-router';

const TransactionsPage = () => {
    const [showPickerFromDateInput, setShowPickerFromDateInput] = useState('');
    const [showPickerToDateInput, setShowPickerToDateInput] = useState('');
    const [infoItemFrom, setInfoItemFrom] = useState('');
    const [infoItemTo, setInfoItemTo] = useState('');
    const [infoPdfFrom, setInfoPdfFrom] = useState('');
    const [infoPdfTo, setInfoPdfTo] = useState('');
    const user = useSelector(state => state.user);
    const { transactions } = useSelector(state => state.transactions);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (showPickerFromDateInput && showPickerToDateInput) {
            const transformedFromDate = moment(showPickerFromDateInput).format('YYYY/MM/DD');
            const transformedToDate = moment(showPickerToDateInput).format('YYYY/MM/DD');
            dispatch(fetchTransactionInterval({ userId: user.id, endDate: transformedToDate, startDate: transformedFromDate }))
            // setShowPickerFromDateInput('');
            // setShowPickerToDateInput('');
        }
    }, [user.id, showPickerFromDateInput, showPickerToDateInput, dispatch]);



    const handleFromDateChange = (e) => {
        const selectedDate = moment(e.target.value).format('YYYY-MM-DD');

        setShowPickerFromDateInput(selectedDate);
        setInfoItemFrom(selectedDate);
        setInfoPdfFrom(moment(e.target.value).format('YYYYMMDD'));
    };

    const handleToDateChange = (e) => {
        const selectedDate = moment(e.target.value).format('YYYY-MM-DD');
        setShowPickerToDateInput(selectedDate);
        setInfoItemTo(selectedDate);
        setInfoPdfTo(moment(e.target.value).format('YYYYMMDD'));
    };

    const handleDownloadPdf = async () => {
        const startDate = moment(infoItemFrom).format('YYYY/MM/DD');
        const endDate = moment(infoItemTo).format('YYYY/MM/DD');
        const userID = user.id;

        try {
            const res = await apiInstance.get(`/core/transactions/createPdf?endDate=${endDate}&startDate=${startDate}&userID=${userID}`)
            const response = await apiInstance.get(`/core/transactions/createPdf`, {
                params: {
                    endDate,
                    startDate,
                    userID,
                },
                responseType: 'blob',
            });

            // const blob = new Blob([response.data], { type: 'application/pdf' });
            const filename = `Transactions_Between_${infoPdfFrom}_and_${infoPdfTo}.pdf`;
            saveAs(response.data, filename);
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    };

    const handleSelectTransaction = (id) => {
        dispatch(fetchTransactionById(id));
        navigate('/transaction')
    }

    return (
        <DashboardLayout type="text">
            <DashboardHeader type="text" title="TRANSACTIONS" />
            <div className='transactions-page-container'>
                <div className="date-picker-container">
                    <div className='inputs-container'>
                        <div>
                            <CustomInput
                                type="date"
                                label='From Date: '
                                id="fromDate"
                                value={showPickerFromDateInput}
                                onChange={handleFromDateChange}
                            />
                        </div>

                        <div>
                            <CustomInput
                                type="date"
                                label='To Date: '
                                id="toDate"
                                value={showPickerToDateInput}
                                onChange={handleToDateChange}
                            />
                        </div>
                    </div>
                    <>
                        <Button onClick={handleDownloadPdf} text='Download PDF'></Button>
                    </>
                </div>

                {transactions.length === 0 ? (<>
                    <div style={{ minHeight: '35vh', marginBottom: '5vh'}}>
                        <p style={{ color: 'white', fontSize: '20px' }}>You don't have any transactions</p>
                    </div>
                </>
                ) : (<>
                    <div className="transactions-list">
                    {
                        transactions.map((transaction, index) => (
                        <TransactionCard
                            key={index}
                            title={`${transaction.senderIban} `}
                            subTitle={moment(transaction.creationDate).format('DD / MM / YYYY | HH:mm')}
                            ammount={String(transaction.amount)}
                            currency={String(transaction.currency)}
                            onClick={() => handleSelectTransaction(transaction.id)}
                        />
                        ))
                    }
                    </div>

                </>
                )}
            </div>
        </DashboardLayout>
    );
};

export default TransactionsPage;
