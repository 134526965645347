import apiInstance from '../axios/apiInstance';

export const getCard = async (id) => {
  try {
    const response = await apiInstance.get(`/core/card?userID=${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getForexRate = async ({ receiverIban, senderIban, amount, fromIdCurrency }) => {
  try {
    const response = await apiInstance.post(`/core/payment/isforex?receiverIban=${receiverIban}&senderIban=${senderIban}`);
    
    if (response.data.value) {
      const rateResponse = await apiInstance.get(`/core/payment/forex/exchange?fromIdCurrency=${fromIdCurrency}&toIdCurrency=${response.data.id}&amount=${amount}`);
      return rateResponse.data;
    }

    return null;  
  } catch (error) {
    throw error;
  }
};


export const getTransferRate = async ( amount, fromIdCurrency, toIdCurrency) => {
  try {
    const response = await apiInstance.get(`/core/payment/forex/exchange?fromIdCurrency=${fromIdCurrency}&toIdCurrency=${toIdCurrency}&amount=${amount}`);
    return response ;
  }
    catch (error) {
      throw error;
    }
}


export const sendMoneyForexBetweenAccounts = async (paymentDetails, ) => {
  try {
    const response = await apiInstance.post('/core/payment/forex', JSON.stringify(paymentDetails) );
    return response.data;
  } catch (error) {
    console.error('Error during Forex transaction:', error);
    throw error; 
  }
};


export const sendMoneyBetweenAccounts = async (paymentDetails, ) => {
  try {
    const response = await apiInstance.post('core/payment/normal', JSON.stringify(paymentDetails) );
    return response.data;
  } catch (error) {
    console.error('Error during Forex transaction:', error);
    throw error; 
  }
};