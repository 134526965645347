import React from 'react';
import './restricted-area.css'; 

const RestrictedArea = ({ bgColor, customStyles, customContainerStyles, children }) => {
  return (
    <div
      className="restricted-area"
      style={{
        backgroundColor: bgColor,
        ...customStyles,
      }}
    >
      <div
        className="restricted-area-container"
        style={{
          ...customContainerStyles,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default RestrictedArea;
