import React from 'react';
import Select from 'react-select';
import './custom-select.css';

const customStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    marginBottom: '10px',
    padding: '0px'
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    overflow: 'scroll',
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#FFD700' : 'lightgrey', 
    borderWidth: '2px', 
    borderStyle: 'solid',
    minHeight: 'auto',
    padding: '5px',
    fontSize: '16px',
    opacity: state.isFocused || state.hasValue ? 1 : 0.2, 
    color: '#6A2BD8', 
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    boxShadow: 'none', 
    transition: 'border-color 0.3s ease, opacity 0.3s ease', 
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#5b0133' : '#9E0059', 
    color: 'lightgrey',
    padding: '10px',
    cursor: 'pointer',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#7C848E',
    fontSize: '16px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'lightgrey',
    fontSize: '16px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'lightgrey', 
  }),
  indicatorSeparator: () => ({
    display: 'none', 
  }),
};

const CustomSelect = ({
  label,
  value,
  onChange,
  options,
  placeholder,
}) => {
  return (
    <div className='custom-select-container'>
      {label && <label className='select-label'>{label}</label>}
      <Select
        value={options.find(option => option.value === value)}
        onChange={onChange}
        options={options}
        placeholder={placeholder || label}
        styles={customStyles}
      />
    </div>
  );
};

export default CustomSelect;
