import apiInstance from '../axios/apiInstance';

export const getTransactions = (userId, size) => {
  return apiInstance.get(`/core/transactions?size=${size}&userID=${userId}`);
};

export const getStoreTransactions = (userId, type, storeId) => {
  return apiInstance.get(`/core/store/all/transactions?storeID=${storeId}&type=${type}&userID=${userId}`);
};

export const getTransactionById = (transactionId) => {
  return apiInstance.get(`/core/transaction/getTransaction?transactionID=${transactionId}`);
};

export const getTransactionInterval = (userId, endDate, startDate) => {
  return apiInstance.get(`/core/transactions/getTransactionsFromInterval?endDate=${endDate}&startDate=${startDate}&userID=${userId}`);
};

export const createTransactionPDF = (userId, endDate, startDate) => {
  return apiInstance.get(`/core/transactions/createPdf?endDate=${endDate}&startDate=${startDate}&userID=${userId}`);
};

export const getPOSTransactions = (posId, type, userId) => {
  return apiInstance.get(`/core/pos/transactions?userID=${userId}&type=${type}&posID=${posId}`);
};
