import React, { useState } from 'react';
import './custom-input.css';

const CustomInput = ({ type = 'text', value = '', placeholder, onChange, onBlur, className, label, isCardInput = false, isCardDate = false, isCVV = false }) => {
  const [inputValue, setInputValue] = useState(value);

  const formatCardNumber = (number) => {
    return number.replace(/\D/g, '').replace(/(.{4})/g, '$1 ').trim();
  };

  const formatCardDate = (date) => {
    const sanitizedValue = date.replace(/\D/g, ''); 
    let month = sanitizedValue.substring(0, 2);   
    const year = sanitizedValue.substring(2, 4); 

    if (month.length === 2 && parseInt(month, 10) > 12) {
      month = '12';
    }

    return `${month}${year ? '/' + year : ''}`;
  };

  const handleInputChange = (e) => {
    let rawValue = e.target.value.replace(/\s/g, '');  

    if (isCardInput) {
      const formattedValue = formatCardNumber(rawValue);  
      setInputValue(formattedValue); 
      e.target.value = rawValue; 
      onChange(e);  
      return;
    }

    if (isCardDate) {
      const formattedDate = formatCardDate(rawValue); 
      setInputValue(formattedDate); 
      e.target.value = formattedDate;  
      onChange(e); 
      return;
    }

    if (isCVV) {
      if (rawValue.length > 3) return;  
      setInputValue(rawValue); 
      e.target.value = rawValue; 
      onChange(e);  
      return;
    }

    setInputValue(e.target.value);
    onChange(e); 
  };

  return (
    <div className="custom-input-container">
      {label && <label className="custom-input-label">{label}</label>}
      <input
        type={isCardInput || isCardDate || isCVV ? 'text' : type}  
        value={inputValue}  
        placeholder={isCardInput ? '1234 5678 9012 3456' : isCardDate ? 'MM/YY' : isCVV ? 'CVV' : placeholder}
        onChange={handleInputChange}
        onBlur={onBlur}
        className={`custom-input ${className} ${inputValue ? 'has-value' : ''}`}
        maxLength={isCardInput ? 19 : isCardDate ? 5 : isCVV ? 3 : undefined} 
      />
    </div>
  );
};

export default CustomInput;
