import React, { useState, useEffect } from 'react';
import './dashboard-layout.css';
import { Comafy } from '../helpers';
import RestrictedArea from '../components/restricted-area';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // For back navigation
import apiInstance from '../axios/apiInstance';

const DashboardLayout = ({ type, children }) => {
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const defaultAccount = useSelector(state => state.bankAccounts.defaultAccount);
  const [imageSrc, setImageSrc] = useState('');
  const [userFullName, setUserFullName] = useState('');

  useEffect(() => {
    if (user.firstName && user.lastName && user.companyName) {
      const fullName = user.firstName
        ? `${user.firstName} ${user.lastName}`
        : user.companyName;

      setUserFullName(fullName.length < 13 ? fullName : `${fullName.substring(0, 16)}...`);
    }
  }, [user.firstName, user.lastName, user.companyName]);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await apiInstance.get(
          `https://lyned.nafram.org/lyned/api/v1/user/image?path=${user.selfiePath}&usrID=${user.id}`, {
          responseType: 'blob',
        });
        if (response.data instanceof Blob) {
          const objectUrl = URL.createObjectURL(response.data);
          setImageSrc(objectUrl);
        }
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    };
    if (user.id && user.selfiePath)
      fetchImage();
  }, [user.id, user.selfiePath]);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <RestrictedArea>
      <div className="dashboard-layout__header" style={{ height: '20vh', justifyContent: 'flex-end'  }}>
        <div className="dashboard-layout__user-info">
          {type === 'text' ? (
            <button onClick={handleBackClick} className="dashboard-layout__back-button">
              &#8592; Back
            </button>
          ) : (
            <>
              <div className="dashboard-layout__avatar">
                <img
                  className="dashboard-layout__avatar-img"
                  src={imageSrc}
                  alt="User Avatar"
                />
              </div>
              <div className="dashboard-layout__user-data">
                <span className="dashboard-layout__full-name">{userFullName}</span>
                <span className="dashboard-layout__balance">
                  Balance: {`${Comafy(defaultAccount.amount)} ${defaultAccount.currency.value}`}
                </span>
              </div>
            </>
          )}
        </div>
        <div className="dashboard-layout__logo">
          <img
            src={require('../assets/360logo.png')}
            className="dashboard-layout__logo-img"
            alt="Logo"
          />
        </div>
      </div>
      <div className="dashboard-layout__content">
        {children}
      </div>
    </RestrictedArea>
  );
};

export default DashboardLayout;
