import React, { useEffect, useState } from 'react';
import './contact-card.css';
import apiInstance from '../axios/apiInstance';

const ContactCard = ({ onPress, containerStyle, type, contactID, selfiePath, name, email }) => {
  const [imageSrc, setImageSrc] = useState('');
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await apiInstance.get(
          `https://lyned.nafram.org/lyned/api/v1/user/image?path=${selfiePath}&usrID=${contactID}`,  
          {
            responseType: 'blob', 
          }
        );
        if (response.data instanceof Blob) {
          const objectUrl = URL.createObjectURL(response.data);
          setImageSrc(objectUrl);
        }
      } catch (error) {
        console.error('Error fetching image:', error);
        setImageSrc(require('../assets/send.png'));  
      }
    };

    if (contactID && selfiePath) {
      fetchImage();
    }
  }, [selfiePath]);

  return (
    <div className="contact-card" style={containerStyle} onClick={onPress}>
      {type !== 'transaction' && (
        <div className="contact-avatar-container">
          <div className="contact-avatar">
            <img
              className="contact-avatar-img"
            //   src={`https://lyned.nafram.org/lyned/api/v1/user/image?usrID=${contactID}&path=${selfiePath}`}
              src={imageSrc} 
              alt="Contact Avatar"
              style={{ borderWidth: 2 }}
              onLoad={(e) => (e.target.style.borderColor = '#D3A1F9')}
              onError={(e) => {
                e.target.style.borderColor = 'red';
                e.target.style.content = "url('/path/to/default/avatar')";
              }}
            />
          </div>
        </div>
      )}
      <div className="contact-info">
        <span className="contact-name">{name}</span>
        <span className="contact-email">{email}</span>
      </div>
    </div>
  );
};

export default ContactCard;
