import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './wallet-page.css'; 
import DashboardLayout from '../../layouts/dashboard-layout';
import DashboardHeader from '../../components/dashboard-header';
import TransactionCard from '../../components/transaction-card';
import ContactCard from '../../components/contact-card';
import { loginUser } from '../../store/slices/user.slice'; 
import { getCard } from '../../store/slices/card.slice'; // 
import { getBankAccounts, fetchDefaultAccount } from '../../store/slices/bankAccounts.slice'; 
import { getUserDetails } from '../../store/slices/user.slice';
import { fetchTransactionById, fetchTransactions } from '../../store/slices/transactions.slice';
import { fetchContacts } from '../../store/slices/contacts.slice';
import localStorageService from '../../services/localStorageService';
import { useNavigate } from 'react-router';



const WalletScreen = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user); 
  const {transactions } = useSelector(state => state.transactions)
  const { contacts } = useSelector(state => state.contacts)
  const token = localStorageService.getItem('token');
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(loginUser({ username:'catalingealapu52@gmail.com', password:'1' }));
  }, [])
  useEffect(() => {
    if(user.username) {
      dispatch(getUserDetails(user.username));
    }
  }, [user.username, token])

  useEffect(() => {
    if(user.id) {
      dispatch(getCard(user.id));
      dispatch(getBankAccounts(user.id));
      dispatch(fetchDefaultAccount(user.id));
      dispatch(fetchTransactions({userId: user.id, size:7}))
      dispatch(fetchContacts(user.id))
    }
  },[user.id])

  const handleTransactionsClick = () => {
    dispatch(fetchTransactions({userId: user.id, size: 9999}))
    navigate('transactions');
    // console.log(id);
  }

  const handleContactClick = (id) => {
    console.log(id);
    
  }
  const handleSelectTransaction = (id) => {
    dispatch(fetchTransactionById(id));
    navigate('/transaction')
}
  

  
  return (
    <DashboardLayout>
      <DashboardHeader />
      <div className="wallet-content-container">
        {/* Transactions section */}
        <div className="wallet-section-header">
          <span className="wallet-section-title">TRANSACTIONS</span>
          <button
            onClick={handleTransactionsClick}
            className="wallet-see-all-button"
          >
            <span className="wallet-see-all-text" onClick={() => {}}>See all</span>
          </button>
        </div>
        <div className="wallet-transactions-container">
          {transactions.length > 0 && transactions.map((transaction, idx) => (
            <TransactionCard
              key={idx}
              title={
                 transaction.senderName
              }
              subTitle={transaction.paymentDetails}
              ammount={transaction.amount}
              currency={transaction.currency}
              onClick={() => handleSelectTransaction(transaction.id) }
            />
          ))}
        </div>

        <span className="wallet-section-title">CONTACTS</span>
        <div className="wallet-contacts-container">
          {contacts.length > 0 && contacts.map((contact, idx) => (
            <ContactCard
              key={idx}
              name={contact.friend.firstName + ' ' + contact.friend.lastName}
              email={contact.friend.email}
              contactID={contact.friend.id}
              selfiePath={contact.friend.selfiePath}
              onClick={() => handleContactClick(contact.id)}
            />
          ))}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default WalletScreen;


