import apiInstance from '../axios/apiInstance'; 

export const getUserDetails = async (username) => {
  try {
    const response = await apiInstance.get(`/user?username=${username}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data.message || 'Failed to fetch user details');
  }
};
