import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import './send-money.css'; 
import DashboardPageLayout from '../../layouts/dashboard-page-layout';
import CustomInput from '../../components/form/custom-input';
import Button from '../../components/button';
import ForexData from '../../components/forex-data';
import { Comafy } from '../../helpers';
import CustomSelect from '../../components/form/custom-select';
import { getForexRate } from '../../store/slices/card.slice';


const SendMoneyPage = () => {
    const dispatch = useDispatch();
    const { bankAccounts, defaultAccount }= useSelector(state => state.bankAccounts);
    // const isForexState = useSelector(state => state.cardReducer.isForex);
    // const forexRate = useSelector(state => state.cardReducer.forexRate);
    

    const [selectedCurrency, setSelectedCurrency] = useState({ value: '' });
    const [selectedIban, setSelectedIban] = useState('');
    const [beneficiaryNameInput, setBeneficiaryNameInput] = useState('');
    const [accountInfoInput, setAccountInfoInput] = useState('');
    const [detailsInput, setDetailsInput] = useState('');
    const [ammountInput, setAmmountInput] = useState('');
    const [selectedAmount, setSelectedAmount] = useState(0);
    const [isCurrencyValid, setIsCurrencyValid] = useState(false);
    const [visibleModal, setVisibleModal] = useState(null);

    useEffect(() => {
        const dataCopy = bankAccounts.map((item, index) => ({
            iban: item.iban,
            accountId: item.id,
            amount: item.amount,
            description: item.currency.description,
            id: item.currency.id,
            value: item.id,
            key: index,
            label: item.currency.value
        }));

        const defaultAccountData = dataCopy.find(item => item.id === defaultAccount.currency.id);
        if (defaultAccountData) {
            setSelectedCurrency(defaultAccountData);
            setSelectedIban(defaultAccountData.iban);
            setSelectedAmount(defaultAccountData.amount);
        }
    }, [bankAccounts, defaultAccount]);

    const handleAmountFocus = () => {
        let amountWithDecimals = ammountInput.split('.');
        if (ammountInput.includes('.')) {
            setAmmountInput(amountWithDecimals[0]);
        }
    };

    const handleBlur = () => {
        if (ammountInput !== '' && !ammountInput.includes('.')) {
            setAmmountInput(ammountInput + '.00');
        }
    };

    const handleAmountInput = (value) => {
        let valueWithCommas = value.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let valueWithoutCommas = value.replace(/,/g, '');
        setAmmountInput(valueWithCommas);

        setTimeout(() => {
            setTimeout(() => {
                if (accountInfoInput && selectedIban && selectedCurrency?.id) {
                    dispatch(getForexRate({
                        receiverIban: accountInfoInput,
                        senderIban: selectedIban,
                        amount: valueWithoutCommas,
                        fromIdCurrency: selectedCurrency.id
                    }));
                }
            }, 1000);
        }, 1000);
    };

    const handleCurrencySelect = (option) => {
        setSelectedCurrency(option);
        setSelectedIban(option.iban);
        setSelectedAmount(option.amount);
        setIsCurrencyValid(true);

        if (!isNextDisabled()) { 
            // dispatch(isForex(accountInfoInput, option.iban, ammountInput, option.id));
        }
    };

    const isNextDisabled = () => {
        return !beneficiaryNameInput || !accountInfoInput || !detailsInput || !isCurrencyValid || ammountInput <= 0;
    };

    const handleNextPress = () => {
        setVisibleModal(1);
    };

    const handleAcceptPress = () => {
        if (ammountInput > selectedAmount) {
            
        } else {
            // dispatch(addNewContact(accountInfoInput, selectedIban, detailsInput, ammountInput, selectedCurrency.label));
            setVisibleModal(null);
        }
    };

    return (
        <div className='send-money-page-container'>
        <DashboardPageLayout
            title="Send Money"
            disableMainBtn={isNextDisabled()}
            onMainBtnPress={handleNextPress}
            mainBtnText="Next"
            extraHeightForScroll={13}
        >
            <div className="user-data-container">
                    <div className="avatarContainer">
                        <img src={require('../../assets/avatar.png')} alt="Avatar" className="avatarImage" />
                    </div>
                    <div className="account-info">
                        <p style={{color: 'lightgrey'}}>Selected Account</p>
                        <p>{selectedIban}</p>
                        <p>Balance: {Comafy(selectedAmount)} {selectedCurrency.label}</p>
                    </div>
            </div>

            <CustomInput
                label="Beneficiary Name"
                value={beneficiaryNameInput}
                onChange={e => setBeneficiaryNameInput(e.target.value)}
            />
            <CustomInput
                label="Friend's Account Number"
                value={accountInfoInput}
                onChange={e => setAccountInfoInput(e.target.value)}
            />
            <CustomInput
                label="Details"
                value={detailsInput}
                onChange={e => setDetailsInput(e.target.value)}
            />
            <div className='amount-currency-container'>
            <CustomSelect
                    value={selectedCurrency}
                    onChange={(option) => handleCurrencySelect(option)}
                    options={bankAccounts.map(acc => ({
                        value: acc.iban,
                        label: acc.currency.value,
                        amount: acc.amount,
                        currency: acc.currency.value
                    }))}
                />
            <CustomInput
                type="number"
                label="Amount"
                value={ammountInput}
                onChange={e => handleAmountInput(e.target.value)}
                onFocus={handleAmountFocus}
                onBlur={handleBlur}
            >
                 
            </CustomInput>
            </div>
            {/* {isForexState && (
                <ForexData
                    senderCurrency={forexRate.currencyToExchange}
                    senderValue={forexRate.amountToExchange}
                    receiverCurrency={forexRate.currencyExchanged}
                    receiverValue={forexRate.amountExchanged}
                />
            )} */}

            <Modal
                isOpen={visibleModal === 1}
                onRequestClose={() => setVisibleModal(null)}
                contentLabel="Confirm Transaction"
            >
                <h2>Please confirm this transaction:</h2>
                <p>Account: {selectedCurrency.label}</p>
                <p>Amount: {ammountInput} {selectedCurrency.label}</p>
                <p>To: {beneficiaryNameInput}</p>
                <p>IBAN: {accountInfoInput}</p>
                <p>Details: {detailsInput}</p>
                <div className="modalButtons">
                    <Button onClick={handleAcceptPress} text='Confirm'>Confirm</Button>
                    <button onClick={() => setVisibleModal(null)} text='Close' outline={true}>Close</button>
                </div>
            </Modal>
        </DashboardPageLayout>
        </div>
    );
};

export default SendMoneyPage;
