import axios from 'axios';
import localStorageService from '../services/localStorageService';

const apiInstance = axios.create({
  baseURL: 'https://lyned.nafram.org/lyned/api/v1',
  headers: {
    'Content-Type': 'application/json',
  },
});

apiInstance.interceptors.request.use(
  (config) => {
    let token = localStorageService.getItem('token');
    
    if (token) {
      config.headers['Authorization'] = `${token}`; 
    }
    return config;
  },
  (error) => error
);


apiInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default apiInstance;