function commafy(number, decimals = 2, decimalSeparator = '.', thousandsSeparator = ',') {
    const roundedNumber = parseFloat(number).toFixed(decimals);
    let integerPart = '',
      fractionalPart = '';
    if (decimals == 0) {
      integerPart = roundedNumber;
      decimalSeparator = '';
    } else {
      let numberParts = roundedNumber.split('.');
      integerPart = numberParts[0];
      fractionalPart = numberParts[1];
    }
    integerPart = integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thousandsSeparator}`);
    return `${integerPart}${decimalSeparator}${fractionalPart}`;
  }

export default commafy