import React, { useState  } from 'react';
import './pay.css'; 
import { useNavigate } from 'react-router';
import CreditCard from '../../components/credit-card';
import DashboardPageLayout from '../../layouts/dashboard-page-layout';

const PayPage = (props) => {
  const [transactionDone, setTransactionDone] = useState(true);

  const navigate = useNavigate();


  const _isNextDisabled = () => {
    return !transactionDone;
  };

  const _handleNextClick = () => {
    
    navigate(-1);
  };



  return (
    <DashboardPageLayout
      alignType={'default'}
      title={'PAY'}
      disableMainBtn={_isNextDisabled()}
      extraHeightForScroll={0}
      onMainBtnClick={() => _handleNextClick()}
      mainBtnText={'Done'}
    >
      <div className="payment-content">
        <CreditCard
          number={'4268 5912 5528 2222'}
          expireDate={'22/03'}
          cvv={'123'}
          rotate
        />
      </div>
    </DashboardPageLayout>
  );
};


export default PayPage;
